import DealerGrade from '@/api/customer/dealer-grade'

import { baseState, baseMutations } from '@/store/mixins'

export const state = {
  ...baseState
}

export const getters = {}

export const mutations = {
  ...baseMutations
}

export const actions = {
  fetch(context, attrubutes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search
        }
      },
      attrubutes
    )
    return DealerGrade.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return DealerGrade.store(data)
  }
}
