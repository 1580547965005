<script>
const sidebarMenu = [
    {
        icon: 'fas fa-th',
        title: 'menu.corporate',
        children: [
            {
                path: '/admin/companies',
                title: 'menu.company',
                permission: 'view-companies'
            },
            {
                path: '/admin/branches',
                title: 'menu.branch',
                permission: 'view-branches'
            },
            {
                path: '/admin/citizenship-statistics',
                title: 'menu.citizenshipStatistic',
                permission: 'view-citizenship-statistics'
            },
            {
                path: '/admin/exchange-rates',
                title: 'menu.exchangeRate',
                permission: 'view-exchange-rates'
            },
            {
                title: 'menu.mobileFeatures',
                children: [
                    {
                        title: 'menu.notification',
                        children: [
                            {
                                path: '/admin/templates',
                                title: 'menu.template',
                                permission: 'view-templates'
                            },
                            {
                                path: '/admin/schedules',
                                title: 'menu.schedule',
                                permission: 'view-schedules'
                            },
                            {
                                path: '/admin/manual-sends',
                                title: 'menu.manualSend',
                                permission: 'view-manual-sends'
                            },
                            {
                                path: '/admin/monitorings',
                                title: 'menu.monitoring',
                                permission: 'view-monitorings'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        icon: 'fas fa-cog',
        title: 'menu.setting',
        children: [
            {
                path: '/admin/front-covers',
                title: 'menu.frontCover',
                permission: 'view-front-covers'
            },
            {
                path: '/admin/approval-flows',
                title: 'menu.approvalFlow',
                permission: 'view-approval-flows'
            },
            {
                path: '/admin/systems',
                title: 'menu.systemSetting',
                permission: 'view-system-settings'
            }
        ]
    },
    {
        icon: 'fas fa-user-shield',
        title: 'menu.auth',
        children: [
            {
                path: '/admin/roles',
                title: 'menu.role',
                permission: 'view-roles'
            },
            {
                path: '/admin/users',
                title: 'menu.user',
                permission: 'view-users'
            }
        ]
    },
    {
        icon: 'fas fa-coins',
        title: 'menu.accounting',
        children: [
            {
                path: '/admin/coa-categories',
                title: 'menu.coaCategory',
                permission: 'view-coa-categories'
            },
            {
                path: '/admin/coa-setups',
                title: 'menu.coaSetup',
                permission: 'view-coa-setups'
            },
            {
                path: '/admin/coa-mappings',
                title: 'menu.coaMapping',
                permission: 'view-coa-mappings'
            },
            {
                path: '/admin/journal-entries',
                title: 'menu.journalEntry',
                permission: 'view-journal-entries'
            },
            {
                path: '/admin/general-ledgers',
                title: 'menu.generalLedger',
                permission: 'view-general-ledgers'
            },
            {
                path: '/admin/trial-balances',
                title: 'menu.trialBalance',
                permission: 'view-trial-balances'
            },
            {
                path: '/admin/income-statements',
                title: 'menu.incomeStatement',
                permission: 'view-income-statements'
            },
            {
                path: '/admin/balance-sheets',
                title: 'menu.balanceSheet',
                permission: 'view-balance-sheets'
            }
        ]
    },
    {
        icon: 'fas fa-donate',
        title: 'menu.creditOperation',
        children: [
            {
                path: '/admin/occupations',
                title: 'menu.occupation',
                permission: 'view-occupations'
            },
            {
                path: '/admin/customers',
                title: 'menu.customerManagement',
                permission: 'view-customers'
            },
            {
                title: 'menu.loanProductManagement',
                children: [
                    {
                        path: '/admin/loan-types',
                        title: 'menu.loanProduct',
                        permission: 'view-loan-types'
                    },
                    {
                        path: '/admin/loan-currencies',
                        title: 'menu.loanCurrency',
                        permission: 'view-loan-currencies'
                    }
                ]
            },
            {
                path: '/admin/repayment-methods',
                title: 'menu.repaymentMethod',
                permission: 'view-repayment-methods'
            },
            {
                title: 'menu.downPayment',
                children: [
                    {
                        path: '/admin/asset-profiles',
                        title: 'menu.assetProfile',
                        permission: 'view-asset-profiles'
                    },
                    {
                        path: '/admin/down-payement-setups',
                        title: 'menu.downPaymentSetup',
                        permission: 'view-down-payment-setups'
                    },
                    {
                        path: '/admin/down-payement-repayments',
                        title: 'menu.downPaymentRepayment',
                        permission: 'view-down-payment-repayments'
                    }
                ]
            },
            {
                path: '/admin/customer-loan-purposes',
                title: 'menu.customerLoanPurpose',
                permission: 'view-customer-loan-purposes'
            },
            {
                path: '/admin/collateral-managements',
                title: 'menu.collateralManagement',
                permission: 'view-collateral-managements'
            },
            {
                path: '/admin/customer-loan-requests',
                title: 'menu.customerLoanRequest',
                permission: 'view-customers'
            },
            {
                path: '/admin/loan-disbursements',
                title: 'menu.loanDisbursement',
                permission: 'view-loan-disbursements'
            },
            {
                path: '/admin/loan-repayments',
                title: 'menu.loanRepayment',
                permission: 'view-loan-repayments'
            },
            {
                path: '/admin/loan-write-offs',
                title: 'menu.loanWriteOff',
                permission: 'view-loan-write-offs'
            },
            {
                path: '/admin/loan-recoveries',
                title: 'menu.loanRecovery',
                permission: 'view-loan-recoveries'
            },
            {
                path: '/admin/loan-transfers',
                title: 'menu.loanTransfer',
                permission: 'view-loan-transfers'
            },
            {
                path: '/admin/loan-reschedules',
                title: 'menu.loanReschedule',
                permission: 'view-loan-reschedules'
            },
            {
                path: '/admin/eod',
                title: 'menu.eod',
                permission: 'view-eod'
            }
        ]
    },
    {
        icon: 'fas fa-business-time',
        title: 'menu.humanResource',
        children: [
            {
                title: 'menu.employeeManagement',
                children: [
                    {
                        path: '/admin/organizational-charts',
                        title: 'menu.organizationalChart',
                        permission: 'view-organizational-charts'
                    },
                    {
                        path: '/admin/organizational-structures',
                        title: 'menu.organizationalStructure',
                        permission: 'view-organizational-structures'
                    },
                    {
                        path: '/admin/job-levels',
                        title: 'menu.jobLevel',
                        permission: 'view-job-levels'
                    },
                    {
                        path: '/admin/job-titles',
                        title: 'menu.jobTitle',
                        permission: 'view-job-titles'
                    },
                    {
                        path: '/admin/job-details',
                        title: 'menu.jobDetail',
                        permission: 'view-job-details'
                    },
                    {
                        path: '/admin/nationalities',
                        title: 'menu.nationality',
                        permission: 'view-nationalities'
                    },
                    {
                        path: '/admin/employee-status',
                        title: 'menu.employeeStatus',
                        permission: 'view-employee-status'
                    },
                    {
                        path: '/admin/employees',
                        title: 'menu.employee',
                        permission: 'view-employees'
                    },
                    {
                        path: '/admin/position-hierarchies',
                        title: 'menu.positionHierarchy',
                        permission: 'view-position-hierachies'
                    }
                ]
            },
            {
                title: 'menu.holidayManagement',
                children: [
                    {
                        path: '/admin/holiday-rules',
                        title: 'menu.holidayRule',
                        permission: 'view-holiday-rules'
                    },
                    {
                        path: '/admin/holiday',
                        title: 'menu.holiday',
                        permission: 'view-holiday'
                    }
                ]
            },
            {
                path: '/admin/shift-managements',
                title: 'menu.shiftManagement',
                permission: 'view-shift-managements'
            },
            {
                title: 'menu.leaveManagement',
                children: [
                    {
                        path: '/admin/leave-sub-items',
                        title: 'menu.leaveSubItem',
                        permission: 'view-leave-sub-items'
                    },
                    {
                        path: '/admin/leave-rules',
                        title: 'menu.leaveRule',
                        permission: 'view-leave-rules'
                    },
                    {
                        path: '/admin/leave-initial-balances',
                        title: 'menu.leaveInitialBalance',
                        permission: 'view-leave-initial-balances'
                    },
                    {
                        path: '/admin/leave-records',
                        title: 'menu.leaveRecord',
                        permission: 'view-leave-records'
                    }
                ]
            },
            {
                title: 'menu.attendanceManagement',
                children: [
                    {
                        path: '/admin/attendance-devices',
                        title: 'menu.attendanceDevice',
                        permission: 'view-attendance-devices'
                    },
                    {
                        path: '/admin/work-schedules',
                        title: 'menu.workSchedule',
                        permission: 'view-work-schedules'
                    },
                    {
                        path: '/admin/daily-attendances',
                        title: 'menu.dailyAttendance',
                        permission: 'view-daily-attendances'
                    }
                ]
            }
        ]
    },
    {
        icon: 'fas fa-users',
        title: 'menu.customer',
        children: [
            {
                path: '/admin/customer-profiles',
                title: 'menu.profile',
                permission: 'view-customer-profiles'
            },
            {
                path: '/admin/dealer-grades',
                title: 'menu.dealerGrade',
                permission: 'view-dealer-grades'
            }
        ]
    },
    {
        icon: 'fas fa-chart-bar',
        title: 'menu.inventory',
        children: [
            {
                path: '/admin/warehouses',
                title: 'menu.warehouse',
                permission: 'view-warehouses'
            },
            {
                title: 'menu.product',
                children: [
                    {
                        path: '/admin/product-categories',
                        title: 'menu.category',
                        permission: 'view-product-categories'
                    },
                    {
                        path: '/admin/product-groups',
                        title: 'menu.group',
                        permission: 'view-product-groups'
                    },
                    {
                        path: '/admin/suppliers',
                        title: 'menu.supplier',
                        permission: 'view-suppliers'
                    },
                    {
                        path: '/admin/uom-categories',
                        title: 'menu.uomCategory',
                        permission: 'view-uom-categories'
                    },
                    {
                        path: '/admin/product-profiles',
                        title: 'menu.productProfile',
                        permission: 'view-product-profiles'
                    }
                ]
            }
        ]
    },
    {
        icon: 'fas fa-users',
        title: 'menu.customerService',
        children: [
            {
                path: '/admin/service-tickets',
                title: 'menu.serviceTicket',
                permission: 'view-service-tickets'
            },
            {
                path: '/admin/repair-product-profiles',
                title: 'menu.repairProductProfile',
                permission: 'view-repair-product-profiles'
            },
            {
                path: '/admin/service-quotations',
                title: 'menu.serviceQuotation',
                permission: 'view-service-quotations'
            },
            {
                path: '/admin/delivery-order-tickets',
                title: 'menu.deliveryOrderTickets',
                // permission: 'view-organizational-charts'
                permission: 'view-delivery-order-tickets'
            }
        ]
    },
    {
        title: 'menu.payrollManagement',
        icon: 'fas fa-hand-holding-usd',
        children: [
            {
                path: '/admin/nssf',
                title: 'menu.nssf',
                permission: 'view-nssf'
            },
            {
                title: 'menu.setup',
                children: [
                    {
                        path: '/admin/payroll-cycle-dates',
                        title: 'menu.payrollCycleDate',
                        permission: 'view-payroll-cycle-dates'
                    },
                    {
                        path: '/admin/pay-user-variables',
                        title: 'menu.payrollFormular',
                        permission: 'view-pay-user-variables'
                    },
                    {
                        path: '/admin/basic-salary-formulas',
                        title: 'menu.basicSalaryFormular',
                        permission: 'view-basic-salary-formulas'
                    },
                    {
                        path: '/admin/basic-salarys',
                        title: 'menu.basicSalary',
                        permission: 'view-basic-salarys'
                    },
                    {
                        path: '/admin/payroll-cycles',
                        title: 'menu.payrollCycle',
                        permission: 'view-payroll-cycles'
                    },
                    {
                        path: '/admin/pension-rate',
                        title: 'menu.pensionRate',
                        permission: 'view-item-allowances'
                    },
                    {
                        path: '/admin/pension-contribution-wage',
                        title: 'menu.pensionContributionWage',
                        permission: 'view-payroll-cycles'
                    },
                    {
                        title: 'menu.payrollItems',
                        children: [
                            {
                                path: '/admin/allowance-items',
                                title: 'menu.allowanceItem',
                                permission: 'view-item-allowances'
                            },
                            {
                                path: '/admin/deduction-items',
                                title: 'menu.deductionItem',
                                permission: 'view-item-deductions'
                            },
                            {
                                path: '/admin/compensation-items',
                                title: 'menu.compensationItem',
                                permission: 'view-item-compensates'
                            },
                            {
                                path: '/admin/incentive-bonus-items',
                                title: 'menu.incentiveBonusItem',
                                permission: 'view-item-incentive-bonus'
                            },
                            {
                                path: '/admin/item-overtimes',
                                title: 'menu.itemOvertime',
                                permission: 'view-item-overtimes'
                            }
                        ]
                    },
                    {
                        path: '/admin/overtime-rule-by-shifts',
                        title: 'menu.otRuleByShift',
                        permission: 'view-overtime-rule-by-shifts'
                    },
                    {
                        path: '/admin/payroll-templates',
                        title: 'menu.payrollTemplate',
                        permission: 'view-payroll-templates'
                    },
                    {
                        path: '/admin/payslip-templates',
                        title: 'menu.payslipTemplate',
                        permission: 'view-payslip-templates'
                    },
                    {
                        path: '/admin/tax-ranges',
                        title: 'menu.taxRange',
                        permission: 'view-tax-ranges'
                    }
                ]
            },
            {
                title: 'menu.operation',
                children: [
                    {
                        path: '/admin/fixed-allowances',
                        title: 'menu.fixedAllowance',
                        permission: 'view-fixed-allowances'
                    },
                    {
                        path: '/admin/fixed-incentive-bonus',
                        title: 'menu.fixedIncentiveBonus',
                        permission: 'view-fixed-incentive-bonus'
                    },
                    {
                        path: '/admin/monthly-allowances',
                        title: 'menu.monthlyAllowance',
                        permission: 'view-monthly-allowances'
                    },
                    {
                        path: '/admin/monthly-deductions',
                        title: 'menu.monthlyDeduction',
                        permission: 'view-monthly-deductions'
                    },
                    {
                        path: '/admin/monthly-compensations',
                        title: 'menu.monthlyCompensation',
                        permission: 'view-monthly-compensations'
                    },
                    {
                        path: '/admin/monthly-compensates',
                        title: 'menu.monthlyCompensate',
                        permission: 'view-monthly-compensates'
                    },
                    {
                        path: '/admin/monthly-incentive-bonus',
                        title: 'menu.monthlyIncentiveBonus',
                        permission: 'view-basic-salarys'
                    },
                    {
                        path: '/admin/monthly-over-times',
                        title: 'menu.monthlyOverTime',
                        permission: 'view-monthly-over-times'
                    },
                    {
                        path: '/admin/payroll-calculations',
                        title: 'menu.payrollCalculation',
                        permission: 'view-payroll-calculations'
                    },
                    {
                        path: '/admin/first-cycle-paids',
                        title: 'menu.firstCyclePaid',
                        permission: 'view-basic-salarys'
                    },
                    {
                        path: '/admin/working-hours',
                        title: 'menu.workingHours',
                        permission: 'view-working-hours'
                    }
                ]
            }
        ]
    },
    {
        icon: 'fas fa-file-invoice',
        title: 'menu.report',
        children: [
            {
                title: 'menu.accounting',
                children: [
                    {
                        path: '/admin/report/coa-lists',
                        title: 'menu.coaList',
                        permission: 'view-report-coa-lists'
                    },
                    {
                        path: '/admin/report/general-journal-records',
                        title: 'menu.generalJournalRecord',
                        permission: 'view-report-general-journal-records'
                    },
                    {
                        path: '/admin/report/general-ledgers',
                        title: 'menu.generalLedger',
                        permission: 'view-report-general-ledgers'
                    },
                    {
                        path: '/admin/report/trial-balances',
                        title: 'menu.trialBalance',
                        permission: 'view-report-trial-balances'
                    },
                    {
                        path: '/admin/report/balance-sheets',
                        title: 'menu.balanceSheet',
                        permission: 'view-report-balance-sheets'
                    },
                    {
                        path: '/admin/report/profit-and-losses',
                        title: 'menu.profitAndLoss',
                        permission: 'view-report-profit-and-losses'
                    }
                ]
            },
            {
                title: 'menu.creditOperation',
                children: [
                    {
                        path: '/admin/report/disbursement-lists',
                        title: 'menu.disbursementList',
                        permission: 'view-report-disbursement-lists'
                    },
                    {
                        path: '/admin/report/loan-repayments',
                        title: 'menu.loanRepayment',
                        permission: 'view-report-loan-repayments'
                    },
                    {
                        path: '/admin/report/loan-overdues',
                        title: 'menu.loanOverdues',
                        permission: 'view-report-loan-overdues'
                    },
                    {
                        path: '/admin/report/loan-drop-outs',
                        title: 'menu.loanDropOut',
                        permission: 'view-report-loan-drop-outs'
                    },
                    {
                        path: '/admin/report/loan-paid-off-by-co',
                        title: 'menu.loanPaidOff',
                        permission: 'view-report-loan-paid-offs'
                    },
                    {
                        path: '/admin/report/loan-written-offs',
                        title: 'menu.loanWrittenOff',
                        permission: 'view-report-loan-write-offs'
                    },
                    {
                        path: '/admin/report/loan-recoveries',
                        title: 'menu.loanRecovery',
                        permission: 'view-report-loan-recoveries'
                    },
                    {
                        path: '/admin/report/customer-lists',
                        title: 'menu.customerList',
                        permission: 'view-report-customer-lists'
                    },
                    {
                        path: '/admin/report/loan-active-by-co',
                        title: 'menu.loanActiveByCo',
                        permission: 'view-report-loan-active-by-co'
                    },
                    {
                        path: '/admin/report/customer-statements',
                        title: 'menu.customerStatement',
                        permission: 'view-report-customer-statements'
                    },
                    {
                        path: '/admin/report/daily-collection-schedules',
                        title: 'menu.dailyCollectionSchedule',
                        permission: 'view-report-daily-collection-schedules'
                    },
                    {
                        path: '/admin/report/summary-of-active-accounts',
                        title: 'menu.summaryOfActiveAccount',
                        permission: 'view-report-summary-of-active-accounts'
                    },
                    {
                        path: '/admin/report/summary-of-portfolio-at-risks',
                        title: 'menu.summaryOfPortfolioAtRisk',
                        permission: 'view-report-summary-of-portfolio-at-risks'
                    },
                    {
                        path: '/admin/report/portfolio-repayments',
                        title: 'menu.portfolioRepayment',
                        permission: 'view-report-portfolio-repayments'
                    },
                    {
                        path: '/admin/report/loan-transaction-summaries',
                        title: 'menu.loanTransactionSummary',
                        permission: 'view-report-loan-transaction-summaries'
                    },
                    {
                        path: '/admin/report/loan-transaction-details',
                        title: 'menu.loanTransactionDetail',
                        permission: 'view-report-loan-transaction-details'
                    },
                    {
                        path: '/admin/report/incentive-performance-by-co',
                        title: 'menu.incentivePerformanceByCo',
                        permission: 'view-report-incentive-performance-by-co'
                    },
                    {
                        path: '/admin/report/device-posting-logs',
                        title: 'devicePostingLog.pageTitle',
                        permission: 'view-report-device-posting-logs'
                    }
                ]
            },
            {
                title: 'menu.humanResource',
                children: [
                    {
                        path: '/admin/report/attendance-details',
                        title: 'menu.attendanceDetail',
                        permission: 'view-attendance-details'
                    },
                    {
                        path: '/admin/report/attendance-summaries',
                        title: 'menu.attendanceSummary',
                        permission: 'view-attendance-summaries'
                    },
                    {
                        path: '/admin/report/leave-balances',
                        title: 'menu.leaveBalance',
                        permission: 'view-report-leave-balances'
                    },
                    {
                        path: '/admin/report/employee-lists',
                        title: 'menu.employeeList',
                        permission: 'view-report-employee-lists'
                    },
                    {
                        path: '/admin/report/employee-profiles',
                        title: 'menu.employeeProfile',
                        permission: 'view-report-employee-profiles'
                    }
                ]
            },
            {
                title: 'menu.payroll',
                children: [
                    {
                        path: '/admin/report/payroll-details',
                        title: 'menu.payrollDetail',
                        permission: 'view-report-payroll-details'
                    },
                    {
                        path: '/admin/report/basic-salary-historys',
                        title: 'menu.basicSalaryHistory',
                        permission: 'view-basic-salarys'
                    },
                    {
                        path: '/admin/report/nssf-reports',
                        title: 'menu.nssf',
                        permission: 'view-basic-salarys'
                    },
                    {
                        path: '/admin/report/payslip',
                        title: 'menu.payslip',
                        permission: 'view-report-payslips'
                    },
                    {
                        path: '/admin/report/summary-report-pension-fund-and-insurances',
                        title: 'menu.summaryPensionAndInsurance',
                        permission: 'view-report-payslips'
                    }
                ]
            }
        ]
    }
]
export default sidebarMenu
</script>
