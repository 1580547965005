import httpClient from "@/http-client";

export const get = (options = {}) => {
    return httpClient
        .get("/api/employees", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/employees", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/employees/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/employees/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id => {
    return httpClient
        .delete(`/api/employees/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const photoUpload = data => {
    return httpClient
        .post("/api/employees/photos", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const position = (options = {}) => {
    return httpClient
        .get("/api/employees/logic-positions", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const status = (options = {}) => {
    return httpClient
        .get("/api/employees/status", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const statusReason = (options = {}) => {
    return httpClient
        .get("/api/employees/status-reason", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const shift = (Option = {}) => {
    return httpClient
        .get("/api/employees/shifts", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const pcDate = (Option = {}) => {
    return httpClient
        .get("/api/employees/pc-date", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const paymentType = (Option = {}) => {
    return httpClient
        .get("/api/employees/payment-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const nationality = (Option = {}) => {
    return httpClient
        .get("/api/employees/nationalities", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const branch = (Option = {}) => {
    return httpClient
        .get("/api/employees/branches", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const moveBranch = data => {
    return httpClient
        .post("/api/employees/move-branches", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};


export const staffType = (Option = {}) => {
    return httpClient
        .get("/api/employees/staff-types", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default {
    get,
    store,
    find,
    update,
    destroy,
    photoUpload,
    position,
    status,
    shift,
    pcDate,
    paymentType,
    nationality,
    branch,
    moveBranch,
    statusReason,
    staffType
};
