import httpClient from '@/http-client'

export const get = (Options = {}) => {
  return httpClient
    .get('/api/dealer-grades', Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/dealer-grades', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export default { get, store }
