<template>
    <div>
        <div class="tw-flex tw-justify-end tw-mb-4">
            <Input
                search
                v-model="search"
                :placeholder="$t('branch.search')"
                :style="width"
                @on-focus="() => (width = 'width: 500px')"
                @on-blur="() => (width = 'width: 300px')"
            />
        </div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            :loading="loading"
            max-height="700"
            stripe
        >
            <template slot-scope="{ row }" slot="contract_expiry_date">
                <span class="tw-font-bold badge bg-warning">
                    {{ row.contract_expiry_date }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="contract_period">
                <span v-if="row.contract_period">
                    {{
                        row.contract_period > 1
                            ? row.contract_period + " Months"
                            : row.contract_period + " Month"
                    }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="monthly_fee">
                <span class="tw-font-bold text-success">
                    {{ formatCurrencyWithCode(row.monthly_fee, row.currency) }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="deposit_amount">
                <span class="tw-font-bold text-blue">
                    {{
                        formatCurrencyWithCode(row.deposit_amount, row.currency)
                    }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="rental_contract_attachment">
                <span v-if="row.rental_contracts.length > 0">
                    <span v-for="(rc, index) in row.rental_contracts" :key="index">
                        <span
                            v-if="rc.rental_contract_attachment.length > 0"
                            class="tw-font-bold text-info"
                        >
                            <ul>
                                <li
                                    class="tw-cursor-pointer tw-border-double tw-border-4 tw-border-sky-500"
                                    v-for="(item,
                                    index) in rc.rental_contract_attachment"
                                    :key="index"
                                    @click="downloadFile(item)"
                                >
                                    <i class="fas fa-download"></i>
                                    {{ item.attached_file_name }}
                                </li>
                            </ul>
                        </span>
                    </span>
                </span>
            </template>
            <template slot-scope="{ row }" slot="address">
                <span v-if="$root.$i18n.locale === 'en'"
                    >{{ row.vil_name_en ? row.vil_name_en + ", " : "" }}
                    {{ row.com_name_en ? row.com_name_en + ", " : "" }}
                    {{ row.dis_name_en ? row.dis_name_en + ", " : "" }}
                    {{ row.pro_name_en ? row.pro_name_en + "." : "-" }}
                </span>
                <span v-else
                    >{{ row.vil_name_kh ? row.vil_name_kh + ", " : "" }}
                    {{ row.com_name_kh ? row.com_name_kh + ", " : "" }}
                    {{ row.dis_name_kh ? row.dis_name_kh + ", " : "" }}
                    {{ row.pro_name_kh ? row.pro_name_kh + "." : "-" }}
                </span>
            </template>
        </Table>
        <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
            <ts-i-pagination v-model="pagination"></ts-i-pagination>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { debounce } from "lodash";
import * as FileDownload from "downloadjs";
export default {
    name: "branchExpireContract",
    data() {
        return {
            loading: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapGetters(["formatCurrencyWithCode"]),
        ...mapState("corporate/branchExpireContract", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.corporate.branchExpireContract.search;
            },
            set(newValue) {
                this.$store.commit(
                    "corporate/branchExpireContract/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "corporate/branchExpireContract/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t("branch.prefixCode"),
                    key: "branch_code",
                    minWidth: 100,
                    sortable: true
                },
                {
                    title: this.$t("branch.nameEn"),
                    key: "branch_name_en",
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t("branch.nameKh"),
                    key: "branch_name_kh",
                    minWidth: 170,
                    sortable: true
                },
                {
                    title: this.$t("branch.phoneNumber"),
                    key: "branch_phone",
                    minWidth: 200,
                    align: "right",
                    sortable: true
                },
                {
                    title: this.$t("branch.contractStartDate"),
                    key: "contract_start_date",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("branch.contractPeriod"),
                    slot: "contract_period",
                    minWidth: 140,
                    align: "center"
                },
                {
                    title: this.$t("branch.contractExpiryDate"),
                    slot: "contract_expiry_date",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("branch.monthlyFee"),
                    slot: "monthly_fee",
                    minWidth: 150,
                    align: "right",
                    sortable: true
                },
                {
                    title: this.$t("branch.depositAmount"),
                    slot: "deposit_amount",
                    minWidth: 150,
                    align: "right",
                    sortable: true
                },
                {
                    title: this.$t("branch.ownerPhoneNumber"),
                    key: "owner_phone_number",
                    minWidth: 200,
                    align: "right",
                    sortable: true
                },
                {
                    title: this.$t("branch.contractAttachment"),
                    slot: "rental_contract_attachment",
                    minWidth: 250,
                    sortable: true
                },
                {
                    title: this.$t("branch.address"),
                    slot: "address",
                    minWidth: 400
                },
                {
                    title: this.$t("branch.remark"),
                    key: "remarks",
                    minWidth: 200
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("corporate/branchExpireContract/fetch", attributes)
                .then(() => {
                    if (this.resources.length == 0) {
                        this.showForm = false;
                    } else {
                        this.showForm = true;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    // this.notice({ type: "error", text: error.message });
                });
        },
        downloadFile(data) {
            FileDownload(data.attached_file);
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
