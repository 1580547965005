import httpClient from '@/http-client'


export const get = (Options = {}) => {
  return httpClient
    .get("/api/customer-profiles", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, Options = {}) => {
  return httpClient
    .get(`/api/customer-profiles/${id}`, Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/customer-profiles', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const update = (id, data) => {
  return httpClient
    .put(`/api/customer-profiles/${id}`, data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const destroy = id => {
  return httpClient
    .delete(`/api/customer-profiles/${id}`)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const approveMulti = data => {
  return httpClient
    .post('/api/customer-profiles/approve-multiples', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const formViewModel = (Options = {}) => {
  return httpClient
    .get(`/api/customer-profiles/form-view-models`, Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const customerExportExcel = data => {
  return httpClient
    .post('/api/customer-profiles/export-excels', data, {
      responseType: 'arraybuffer'
    })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error.response.data))
}

export default {
  get,
  find,
  store,
  update,
  destroy,
  formViewModel,
  approveMulti,
  customerExportExcel
}