import employee from "@/api/human-resource/employee";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    positions: [],
    status: [],
    statusReason: [],
    shifts: [],
    pcDates: [],
    paymentTypes: [],
    nationalities: [],
    branches: [],
    staffTypes: []
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_POSITION(state, positions) {
        state.positions = positions;
    },
    SET_STATUS(state, data) {
        state.status = data;
    },
    SET_STATUS_REASON(state, data) {
        state.statusReason = data;
    },
    SET_SHIFTS(state, data) {
        state.shifts = data;
    },
    SET_PC_DATES(state, data) {
        state.pcDates = data;
    },
    SET_PAYMENT_TYPES(state, data) {
        state.paymentTypes = data;
    },
    SET_NATIONALITIES(state, data) {
        state.nationalities = data;
    },
    SET_BRANCHES(state, data) {
        state.branches = data;
    },
    SET_STAFF_TYPE(state, data) {
        state.staffTypes = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return employee.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return employee.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await employee.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return employee.update(id, data);
    },

    destroy(context, id) {
        return employee.destroy(id);
    },

    photoUpload(context, data) {
        return employee.photoUpload(data);
    },
    async getPosition(context, attributes = {}) {
        let response = await employee.position({ params: attributes });
        context.commit("SET_POSITION", response.data);
    },

    async getStatus(context, attributes = {}) {
        let response = await employee.status({ params: attributes });
        context.commit("SET_STATUS", response.data);
    },

    async getStatusReason(context, attributes = {}) {
        let response = await employee.statusReason({ params: attributes });
        context.commit("SET_STATUS_REASON", response.data);
    },

    async getShift(context, attributes = {}) {
        let response = await employee.shift({ params: attributes });
        context.commit("SET_SHIFTS", response.data);
    },

    async getPcDate(context, attributes = {}) {
        let response = await employee.pcDate({ params: attributes });
        context.commit("SET_PC_DATES", response.data);
    },

    async getPaymentType(context, attributes = {}) {
        let response = await employee.paymentType({ params: attributes });
        context.commit("SET_PAYMENT_TYPES", response.data);
    },
    async getNationality(context, attributes = {}) {
        let response = await employee.nationality({ params: attributes });
        context.commit("SET_NATIONALITIES", response.data);
    },
    async getBranch(context, attributes = {}) {
        let response = await employee.branch({ params: attributes });
        context.commit("SET_BRANCHES", response.data);
    },
    async getStaffType(context, attributes = {}) {
        let response = await employee.staffType({ params: attributes });
        context.commit("SET_STAFF_TYPE", response.data);
    },
    moveBranch(context, data) {
        return employee.moveBranch(data);
    }
};
