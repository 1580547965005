export default [
	{
		path: '/admin/service-tickets',
		name: 'service-ticket',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-ticket')
	},
	{
		path: '/admin/service-tickets/create',
		name: 'service-ticket-create',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-ticket/create.vue')
	},
	{
		path: '/admin/service-tickets/:id/edit',
		name: 'service-ticket-edit',
		meta: { requiresAuth: true },
		component: () => import('@/pages/customer-service/service-ticket/edit.vue')
	},
]