import serviceQuotation from '@/api/customer-service/service-quotation'

import { baseState, baseMutations } from '@/store/mixins'
import { split, isEmpty } from 'lodash'

export const state = {
  ...baseState,
  formViewDatas: [],
  branch_id: [],
  ticket_status: undefined
}

export const getters = {

}

export const mutations = {
  ...baseMutations,
  SET_BRANCH_ID(state, value) {
    state.branch_id = value
  },
  SET_TICKET_STATUS(state, value) {
    state.ticket_status = value
  },
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formViewDatas = data;
    } else {
      split(index, ",").forEach(el => {
        state.formViewDatas[el] = data[el];
      });
    }
  },
}

export const actions = {
  fetch(context, attrubutes = {}) {
    const params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          branch_id: context.state.branch_id,
          search: context.state.search,
          ticket_status: context.state.ticket_status,
        }
      },
      attrubutes
    )
    return serviceQuotation.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return serviceQuotation.store(data)
  },
  update(context, { id, data }) {
    return serviceQuotation.update(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await serviceQuotation.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return serviceQuotation.destroy(id)
  },
  async getFormViewModel(context, { params, index }) {
    let response = await serviceQuotation.formViewModel({ params });
    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return Promise.resolve(response);
  },
}
