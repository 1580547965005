export default [
    {
        path: "/admin/report/attendance-details",
        name: "attendance-detail",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/human-resource/attendance-detail.vue")
    },
    {
        path: "/admin/report/attendance-summaries",
        name: "attendance-summary",
        meta: { requiresAuth: true },
        component: () =>
            import("@/pages/report/human-resource/attendance-summary.vue")
    },
    {
        path: "/admin/report/employee-profiles",
        name: "employee-profile",
        meta: { requiresAuth: true },
        component: () => import("@/pages/report/human-resource/employee-profile.vue")
    }
];
